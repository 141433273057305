import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { LovConfigs } from 'src/app/interfaces/lov-configs'
import { LovItem } from 'src/app/interfaces/lov-item'
import { Resp } from 'src/app/interfaces/response'
import { apis } from '../../environments/environment'
import { ConstantsService } from './constants.service'

@Injectable({
    providedIn: 'root'
})
export class LovService {
    baseUrl: string

    constructor (
        public http: HttpClient,
        public cs: ConstantsService
    ) {
        this.baseUrl = apis.baseUrl + '/lov'
    }

    async getPromiseList (resource: string, configs?: LovConfigs): Promise<LovItem> {
        const url = `${this.baseUrl}/${resource}`
        const params = { ...configs }
        const data: LovItem = { }

        const resp: Resp<LovItem> = await firstValueFrom(this.http.get<any>(url, { params }))
        if (resp.success) {
            return resp.data
        }

        return data
    }

    /**
     * List of Customers
     *
     * @param LovConfigs configs Optional parameter for LoV Configurations
     * @returns Promise<LovItem[]>
     */
    async customers (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('customers', configs)
    }

    /**
     * Write LoVs calls here
     */

    /**
     * Device Brands
     * @param configs config LovConfigs
     * @returns LovItem
     */
    async deviceBrands (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('device-brands', configs)
    }

    /**
     * Device Models
     * @param configs LovConfigs
     * @returns LovItem
     */
    async deviceModels (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('device-models', configs)
    }

    /**
     * WorkShop Super Seeders
     * @param configs LovConfigs
     * @returns LovItem
     */
    async workshopSuperSeeders (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('workshop-super-seeders', configs)
    }

    /**
     * customerUsers
     * @param configs LovConfigs
     * @returns LovItem
     */
    async customerUsers (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('customer-users', configs)
    }

    /**
     * Customer Super Seeders
     * @param configs LovConfigs
     * @returns LovItem
     */
    async customerEmailTemplates (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('customer-email-templates', configs)
    }

    /**
     * Customer Super Seeders
     * @param configs LovConfigs
     * @returns LovItem
     */
    async workshopEmailTemplates (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('workshop-email-templates', configs)
    }

    /**
     * Customer Super Seeders
     * @param configs LovConfigs
     * @returns LovItem
     */
    async customerSuperSeeders (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('customer-super-seeders', configs)
    }

    /**
     * Schools/Locations
     * @param configs LovConfigs
     * @returns LovItem
     */
    async schoolLocations (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('schools', configs)
    }

     /**
     * Schools/Locations
     * @param configs LovConfigs
     * @returns LovItem
     */
    async dropOfLocation (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('ctm-locations', configs)
    }

    /**
     * Cell Tech Managed Locations/Schools of Customers
     * @param configs LovConfigs
     * @returns LovItem
     */
    async ctmLocations (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('ctm-locations', configs)
    }

    /**
     * Drivers
     * @param configs LovConfigs
     * @returns LovItem
     */
    async drivers (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('drivers', configs)
    }

    /**
     * Warranty Providers
     * @param configs LovConfigs
     * @returns LovItem
     */
    async warrantyProviders (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('warranty-providers', configs)
    }

    /**
     * Customer Inventory Tags
     * @param configs LovConfigs
     * @returns LovItem
     */
    async customerInventoryTags (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('customer-inventory-tags', configs)
    }

    /**
     * Warranty Providers
     * @param configs LovConfigs
     * @returns LovItem
     */
    async detailedWarrantyProviders (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('detailed-warranty-providers', configs)
    }

     /**
     * Warranty Providers
     * @param configs LovConfigs
     * @returns LovItem
     */
    async authProviders (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('auth-providers', configs)
    }

    /**
     * Claim Type Names
     * @param configs LovConfigs
     * @returns LovItem
     */
    async claimTypeNames (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('claim-type-names', configs)
    }

    /**
     * Claim Types
     * @param configs LovConfigs
     * @returns LovItem
     */
    async claimTypes (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('claim-types', configs)
    }

    /**
     * Claim Types Names
     * @param configs LovConfigs
     * @returns LovItem
     */
    async filtererdClaimTypesNames (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('claim-type-names', configs)
    }

    /**
     * Claim Sub-Types
     * @param configs LovConfigs
     * @returns LovItem
     */
    async claimSubTypes (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('claim-sub-types', configs)
    }

    /**
     * Unfix Reasons
     * @param configs LovConfigs
     * @returns LovItem
     */
    async unfixReasons (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('unfix-reasons', configs)
    }

    /**
     * NotRepair Reasons
     * @param configs LovConfigs
     * @returns LovItem
     */
    async nonRepairReasons (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('non-repair-reasons', configs)
    }

    /**
     * NotRepair Revocation Reasons
     * @param configs LovConfigs
     * @returns LovItem
     */
    async nonRepairRevocationReasons (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('non-repair-revocation-reasons', configs)
    }

    /**
     * Void QC Reasons
     * @param configs LovConfigs
     * @returns LovItem
     */
    async voidQcReasons (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('void-qc-reasons', configs)
    }

    /**
     * Part Names
     * @param configs LovConfigs
     * @returns LovItem
     */
    async partNames (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('part-names', configs)
    }

    /**
     * Part Inventories
     * @param configs LovConfigs
     * @returns LovItem
     */
    async partInventories (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('part-inventories', configs)
    }

    /**
     * Part Qualities
     * @param configs LovConfigs
     * @returns LovItem
     */
    async partQualities (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('part-qualities', configs)
    }

    /**
     * Operating Systems
     * @param configs LovConfigs
     * @returns LovItem
     */
    async operatingSystems (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('operating-systems', configs)
    }

    /**
     * Tags
     * @param configs LovConfigs
     * @returns LovItem
     */
    async tags (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('tags', configs)
    }

    /**
     * Form Factors
     * @param configs LovConfigs
     * @returns LovItem
     */
    async formFactors (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('form-factors', configs)
    }

    /**
     * Fault Types
     * @param configs LovConfigs
     * @returns LovItem
     */
    async faultTypes (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('fault-types', configs)
    }

    /**
     * Countries
     * @param configs LovConfigs
     * @returns LovItem
     */
    async countries (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('countries', configs)
    }

    /**
     * States
     * @param configs LovConfigs
     * @returns LovItem
     */
    async states (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('states', configs)
    }

    /**
     * Cities
     * @param configs LovConfigs
     * @returns LovItem
     */
    async cities (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('cities', configs)
    }

    /**
     * Zones
     * @param configs LovConfigs
     * @returns LovItem
     */
    async zones (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('zones', configs)
    }

    /**
     * Vehicle Brands
     * @param configs LovConfigs
     * @returns LovItem
     */
    async vehicleBrands (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('vehicle-brands', configs)
    }

    /**
     * Vehicle Models
     * @param configs LovConfigs
     * @returns LovItem
     */
    async vehicleModels (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('vehicle-models', configs)
    }

    /**
     * Customer Email Template categories
     * @param configs LovConfigs
     * @returns LovItem
     */
    async customerEmailTemplateCategories (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('customer-email-template-categories', configs)
    }

    /**
     * Workshop Email Template categories
     * @param configs LovConfigs
     * @returns LovItem
     */
    async workshopEmailTemplateCategories (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('workshop-email-template-categories', configs)
    }

    /**
     * Workshops
     * @param configs LovConfigs
     * @returns LovItem
     */
    async workshops (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('workshops', configs)
    }

    /**
     * Workshop Users
     * @param configs LovConfigs
     * @returns LovItem
     */
    async workshopUsers (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('workshop-users', configs)
    }

    /**
     * Users
     * @param configs LovConfigs
     * @returns LovItem
     */
    async users (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('users', configs)
    }

    /**
     * User Roles
     * @param configs LovConfigs
     * @returns LovItem
     */
    async roles (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('roles', configs)
    }


    /**
         * Workshop Roles
         * @param configs LovConfigs
         * @returns LovItem
         */
    async workshopRoles (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('workshop-roles', configs)
    }

    /**
         * Customer Roles
         * @param configs LovConfigs
         * @returns LovItem
         */
    async customerRoles (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('customer-roles', configs)
    }


    /**
     * User Permissions
     * @param configs LovConfigs
     * @returns LovItem
     */
    async workshopPermissions (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('detailed-workshop-permissions', configs)
    }

    async customerPermissions (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('detailed-customer-permissions', configs)
    }

    /**
     * Schools Lov
     * @param configs LovConfigs
     * @returns LovItem
     */
    async schools (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('schools', configs)
    }

    /**
     * Aborted Pickup Reasons
     * @param configs LovConfigs
     * @returns LovItem
     */
    async abortedPickupReasons (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('aborted-reasons', configs)
    }

    /**
     * Aborted Dropoff Reasons
     * @param configs LovConfigs
     * @returns LovItem
     */
    async abortedDropoffReasons (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('aborted-dropoff-reasons', configs)
    }

    /**
     * Parts Inventory Other Owners List
     * @param configs LovConfigs
     * @returns LovItem
     */
    async partsInventoryOtherOwners (configs?: LovConfigs): Promise<LovItem> {
        return this.getPromiseList('parts-inventory-other-owners', configs)
    }
}
