import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'fixedDecimal'
})
export class fixedDecimal implements PipeTransform {
    transform (value: any, decimalPoints: number = 2): string {
        // Check if value is a valid number
        if (value == null || isNaN(value)) {
            return '' // Return an empty string for invalid input
        }

        // Convert the value to a number (if it's not already)
        const numericValue = Number(value)

        // Apply toFixed to round to the specified decimal points
        return numericValue.toFixed(decimalPoints)
    }
}
