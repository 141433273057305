<input type="file" #fileInputRef class="d-none" (change)="onFilesChange($event)" [multiple]="multipleUpload" />

<div #uploaderWrapper class="uploader-wrapper" (dragleave)="onDragLeave($event)" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
    <div class="instructions">
        @if (!filesList) {
            <!-- <i class="upload-file fa-solid fa-file-arrow-up"></i> -->
            <i class="upload-file fa-solid fa-arrow-up-from-bracket" (click)="triggerClickOnFile()"></i>
            <p class="msg">{{ lang.upload_message }}</p>
        } @else if (!multipleUpload) {
            <!--If multiple upload is not enabled-->
            @if (this.filesList.item(0)?.name) {
                @if (singleImageLoadingWaiting == LoadingStatusEnum.FETCHING) {
                    <app-rg-spinner [size]="'50px'"></app-rg-spinner>
                } @else {
                    <img class="known-file" [src]="singleUploadSelectedFileSrc" />
                    <p class="filename">{{ this.filesList.item(0)?.name }}</p>
                }

                @if (getFileType(this.filesList.item(0)) == 'unknown') {
                    <p class="error-msg">{{ lang.invalid_file_type }}</p>
                }
            } @else {
                <i class="upload-file fa-solid fa-arrow-up-from-bracket" (click)="triggerClickOnFile()"></i>
                <p class="msg">{{ lang.upload_message }}</p>
            }
        } @else if (multipleUpload) {
            <!-- If multiple file uploading is enabled -->
            <div class="preview-images">
                <div class="row mb-4">
                    @for (image of selectedFileSrc; track image; let idx = $index) {
                        <div class="ms-2 col-md-6 mt-1" [style]="'width: 200px; height: 200px;margin-bottom:25px'">
                            @if (selectedFileSrc[idx].imageLoadingStatus == LoadingStatusEnum.FETCHING) {
                                <div class="d-flex justify-content-center align-items-center w-100 h-100">
                                    <app-rg-spinner [size]="'50px'"></app-rg-spinner>
                                </div>
                            } @else if (selectedFileSrc[idx].imageLoadingStatus == LoadingStatusEnum.DONE) {
                                <div
                                    class="d-flex flex-column box position-relative"
                                    [class.file-status-failed]="parallelUploadStatus[selectedFileSrc[idx]['name']].uploadStatus == FileStatusEnum.FAILED"
                                >
                                    @if (parallelUploadStatus[selectedFileSrc[idx]['name']].uploadStatus == FileStatusEnum.FAILED) {
                                        <div class="position-absolute err-overlay" (click)="stopPropagation($event)"></div>
                                        <div class="position-absolute danger err-overlay-msg" (click)="stopPropagation($event)">
                                            {{ parallelUploadStatus[selectedFileSrc[idx]['name']].uploadResponse.body.errors.general }}
                                        </div>
                                    }
                                    <div class="position-absolute" [style]="'top:2px;right:8px;'">
                                        <button type="button" class="rg sm danger ms-1" (click)="confirmDeleteFile($event, idx)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                    <div
                                        class="position-absolute"
                                        [class.delete-overlay]="selectedFileSrc[idx].deleteFromServerWaiting && selectedFileSrc[idx].deleteFromServerWaiting == LoadingStatusEnum.FETCHING"
                                        [style]="'top:0px;left:0px;'"
                                    >
                                        <app-rg-spinner class="delete-loader" [size]="'50px'" [style.display]="'none'"></app-rg-spinner>
                                    </div>
                                    <!-- <div class="position-absolute" [style]="'top:2px;right:35px;'">
                                        <button type="button" class="rg sm info ms-1" (click)="changeFile($event)">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                    </div> -->
                                    <div [style]="'width: 150px;height:150px;'">
                                        <img [src]="selectedFileSrc[idx]['src']" [style]="'width: 100%;height:100%;'" />
                                    </div>
                                    <div>
                                        <p class="rg primary">{{ selectedFileSrc[idx]['name'].slice(0, 18) + (selectedFileSrc[idx]['name'].length > 20 ? '...' : '') }}</p>
                                    </div>
                                    @if (multipleUpload && parallelLoading) {
                                        <div class="mb-2">
                                            <div class="multi-upload-progress-bar">
                                                <div class="progress-track"></div>
                                                <div class="completed" [style.width]="parallelUploadStatus[selectedFileSrc[idx]['name']]['uploadProgess'] + '%'"></div>
                                                <p class="percentage">{{ parallelUploadStatus[selectedFileSrc[idx]['name']]['uploadProgess'] }}%</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    @if (selectedFileSrc.length > 0) {
                        <div class="ms-2 col-md-6 mt-1" [style]="'width: 200px; height: 200px;margin-bottom:25px'">
                            <div
                                class="d-flex box position-relative w-100 h-100 justify-content-center align-items-center select-more-file-tile"
                                style="padding-bottom: 15px"
                                (click)="triggerClickOnFile()"
                            >
                                <i class="fa-solid fa-plus select-more-file"></i>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
        @if (this.validationResult === true && !parallelLoading) {
            <div class="progress-bar mb-4" style="width: 80%">
                <div class="progress-track"></div>
                <div class="completed" [style.width]="this.uploadProgress + '%'"></div>
                <p class="percentage">{{ this.uploadProgress }}%</p>
            </div>
        }
    </div>
</div>
