import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core'
import { TooltipConfigs } from './rg-tooltip.directive'

@Component({
    // eslint-disable-next-line
    selector: 'app-tooltip',
    templateUrl: './rg-tooltip.component.html',
    styleUrls: ['./rg-tooltip.component.scss']
})
export class RgTooltipComponent implements OnInit, AfterViewInit {
    @Input() configs: TooltipConfigs = { } as TooltipConfigs

    @Input() styles: any = { }

    // @ViewChild('tooltipRef', { static: false}) tooltipRef: TemplateRef<any>
    show: boolean = false

    constructor (
        private el: ElementRef
    ) {

    }

    ngOnInit () {
        this.setDefaultStyle()
    }

    ngAfterViewInit (): void {

    }

    setDefaultStyle () {
        const styles: object = {
            rgTooltipSuccess: '#dfffe6',
            rgTooltipSuccessBorder: '#dfffe6',

            rgTooltipWarning: '#fff59b',
            rgTooltipWarningBorder: '#fff59b',

            rgTooltipInfo: '#47B5FF',
            rgTooltipInfoBorder: '#47B5FF',

            rgTooltipDanger: '#ffe2e2',
            rgTooltipDangerBorder: '#ffe2e2',

            rgTooltipMinWidth: '110px',
            rgTooltipWidth: 'auto'
        }

        Object.entries(styles).map(([key, value]) => {
            const val = this.styles.hasOwnProperty(key) ? this.styles[key] : value
            this.el.nativeElement.style.setProperty(`--${key}`, val)
        })
    }
}
