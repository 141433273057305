import { IAlertsModule } from 'src/app/libs/ialert/ialerts.module'
import { IRatingModule } from 'src/app/libs/irating/irating.module'

import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReadMoreComponent } from 'src/app/directives/read-more.component'
import { SmartClickDirective } from 'src/app/directives/smart-click.directive'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { ResponseComponent } from './response/response.component'

import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { FilterPipe } from 'src/app/pipes/filter.pipe'
import { AuthDirective } from '../directives/auth.directive'
import { CustomerConfigDirective } from '../directives/customer-config.directive'
import { TrimInputDirective } from '../directives/trim-input.directive'
import { CallbackPipe } from '../pipes/callback.pipe'
import { CommaSeparationPipe } from '../pipes/comma-separation.pipe'
import { ConstantsPipe } from '../pipes/constants.pipe'
import { DynamicKeysReaderPipe } from '../pipes/dynamic-keys-reader.pipe'
import { FileSizeConverterPipe } from '../pipes/file-size-converter.pipe'
import { fixedDecimal } from '../pipes/fixedDecimal.pipe'
import { FormatDatePipe } from '../pipes/format-date.pipe'
import { SafeHtmlPipe } from '../pipes/safe-html.pipe'
import { SuffixPipe } from '../pipes/suffix.pipe'
import { BreadcrumsComponent } from './breadcrums/breadcrums.component'
import { GeneralChartModule } from './general-chart/general-chart.module'
import { IPaginationComponent } from './ipagination/ipagination.component'
import { LoaderComponent } from './loader/loader.component'
import { NoDataComponent } from './no-data/no-data.component'
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component'
import { RgAlertComponent } from './rg-alert/rg-alert.component'
import { RgAutoCompleteComponent } from './rg-auto-complete/rg-auto-complete.component'
import { RgBadgeComponent } from './rg-badge/rg-badge.component'
import { RgButtonComponent } from './rg-button/rg-button.component'
import { CollapsibleDirective } from './rg-collapsible/rg-collapsible.directive'
import { RgDialogModule } from './rg-dialog/rg-dialog.module'
import { RgErrorsComponent } from './rg-errors/rg-errors.component'
import { RgFileUploaderComponent } from './rg-file-uploader/rg-file-uploader.component'
import { RgSelectComponent } from './rg-select/rg-select.component'
import { RgSpinnerComponent } from './rg-spinner/rg-spinner.component'
import { RgTooltipComponent } from './rg-tooltip/rg-tooltip.component'
import { RgTooltipDirective } from './rg-tooltip/rg-tooltip.directive'
import { DragDropDirective } from './rgtable/drag-drop-directive'
import { RgtableComponent } from './rgtable/rgtable.component'
import { SkeletonTabelLoaderComponent } from './skeleton-tabel-loader/skeleton-tabel-loader.component'
import { TaIdentifierComponent } from './ta-identifier/ta-identifier.component'

@NgModule({
    imports: [
        CommonModule,
        IAlertsModule,
        IRatingModule,
        GeneralChartModule,
        FormsModule,
        RouterModule,
        RgDialogModule.forRoot()
    ],
    declarations: [
        // Pipes
        FilterPipe, SafeHtmlPipe, ConstantsPipe, RgtableComponent,
        SuffixPipe, CallbackPipe, DynamicKeysReaderPipe, FormatDatePipe,
        CommaSeparationPipe, FileSizeConverterPipe, fixedDecimal,

        // Components
        ResponseComponent, PageNotFoundComponent, LoaderComponent,
        ReadMoreComponent, RgButtonComponent, RgSpinnerComponent,
        SkeletonTabelLoaderComponent, RgAutoCompleteComponent,
        IPaginationComponent, BreadcrumsComponent, NoDataComponent,
        NotAuthorizedComponent, RgErrorsComponent, RgAlertComponent,
        RgTooltipComponent, RgBadgeComponent, RgFileUploaderComponent,
        TaIdentifierComponent, RgSelectComponent,

        // Directives
        TrimInputDirective, CollapsibleDirective, DragDropDirective,
        RgTooltipDirective, SmartClickDirective, AuthDirective,
        CustomerConfigDirective
    ],
    exports: [
        // Modules
        CommonModule, RgDialogModule, IRatingModule, IAlertsModule, GeneralChartModule,

        // Pipes
        FilterPipe, SafeHtmlPipe, ConstantsPipe, RgtableComponent,
        SuffixPipe, CallbackPipe, DynamicKeysReaderPipe, FormatDatePipe,
        CommaSeparationPipe, FileSizeConverterPipe, fixedDecimal,

        // Components
        ResponseComponent, PageNotFoundComponent, LoaderComponent,
        ReadMoreComponent, RgButtonComponent, RgSpinnerComponent,
        SkeletonTabelLoaderComponent, RgAutoCompleteComponent,
        IPaginationComponent, BreadcrumsComponent, NoDataComponent,
        NotAuthorizedComponent, RgErrorsComponent, RgAlertComponent,
        RgTooltipComponent, RgBadgeComponent, RgFileUploaderComponent,
        TaIdentifierComponent, RgSelectComponent,

        // Directives
        TrimInputDirective, CollapsibleDirective, DragDropDirective,
        RgTooltipDirective, SmartClickDirective, AuthDirective,
        CustomerConfigDirective
    ]
})
export class SharedModule { }
