import { Injectable } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { RgHttpClient } from 'src/app/services/rg-http-client'
import { apis } from 'src/environments/environment'

@Injectable()
export class RgFileUploaderService {

    constructor (public http: RgHttpClient, public api: ApiService) {
    }

    fileDelete (deleteUrl: string, id: number) {
        const url = `${apis.baseUrl}/${deleteUrl}/${id}`

        return this.http.delete<any>(url)
    }
}
